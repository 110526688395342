<template>
  <div class="clientManagement">
    <div class="content">
      <div class="title">
        <div>客户端管理</div>
        <div class="tool">
          <el-input
            v-model.trim="searchValue"
            style="width:220px;margin-right:20px"
            size="small"
            placeholder="请输入"
            >
            <i slot="suffix" class="el-input__icon el-icon-search" @click="handleSearch(1)"></i>
          </el-input>
          <el-button size="small" type="primary" icon="el-icon-plus" @click="handleEdit('')">添加</el-button>
        </div>
      </div>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="100">
            <template slot-scope="scope">
              <span>{{(page-1)*10+scope.$index+1}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="名称"/>
          <el-table-column prop="contractScope" label="签约权限">
            <template slot-scope="scope">
              <span v-for="item in scope.row.contractScope.split(',')" :key="item.value">
                {{contract[item] || item}}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="eventCallbackUrl" label="事件回调地址"/>
          <el-table-column label="授权模式">
            <template slot-scope="scope">
              <span v-if="scope.row.isAutoMode">默认授权</span>
              <span v-else>
                <span v-if="scope.row.isCode">授权码</span>
                <span v-if="scope.row.isImplicit"> 隐藏式</span>
                <span v-if="scope.row.isPassword"> 密码式</span>
                <span v-if="scope.row.isClient"> 凭证式</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="isEnabled" label="授权状态">
            <template slot-scope="scope">
              <span :class="[scope.row.isEnabled?'greenStatus':'redStatus']">{{scope.row.isEnabled?'启用':'禁用'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120" align="center">
            <template slot-scope="scope">
              <img 
                src="@/assets/images/common/edit.png" 
                style="margin-right:20px"
                @click="handleEdit(scope.row)"
              />
              <img src="@/assets/images/common/del.png"  @click="comfirm(scope.row)"/>
            </template>
          </el-table-column>
      </el-table>
      <pagination :total="total" :page.sync="page" :size.sync="size"  @getTableData="handleSearch"/>
    </div>
    <!-- 添加、编辑弹窗 -->
    <el-dialog
      :title="rowData?'编辑':'新增'"
      :visible="visible"
      width="500px"
      :before-close="handleClose"
      custom-class="clientDialog"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" class="info" :show-message="false">
        <el-form-item prop="name" class="item">
          <label>名称</label>
          <el-input v-model="form.name" clearable placeholder="请输入"/>
        </el-form-item>
        <el-form-item prop="isEnabled" class="item">
          <label style="margin-right:10px">是否启用</label>
          <el-switch
              v-model="form.isEnabled"
              active-color="#2DEB79"
              inactive-color="#B9B9B9"
              :active-value="true"
              :inactive-value="false"
            />
        </el-form-item>
        <el-form-item prop="clientId" class="item">
          <label>编号</label>
          <div class="itemContent">
            <el-input v-model="form.clientId" clearable placeholder="请输入"/>
            <el-button v-if="!rowData" size="small" @click="getId">重置</el-button>
          </div>
        </el-form-item>
        <el-form-item prop="clientSecret" class="item">
          <label>秘钥</label>
          <div class="itemContent">
            <el-input v-model="form.clientSecret" clearable placeholder="请输入"/>
            <el-button size="small" @click="getSecret">重置</el-button>
          </div>
        </el-form-item>
        <el-form-item prop="contractScope" class="item">
          <label>签约权限</label>
          <el-select v-model="form.contractScope" multiple>
            <el-option v-for="item in powerList" :key="item.value" :value="item.value" :label="item.name"/>
          </el-select>
        </el-form-item>
        <el-form-item prop="eventCallbackUrl" class="item">
          <label>事件回调地址</label>
          <el-input v-model="form.eventCallbackUrl" clearable placeholder="请输入"/>
        </el-form-item>
        <el-form-item prop="isAutoMode" class="item">
          <label style="margin-right:10px">默认授权</label>
          <el-switch
            v-model="form.isAutoMode"
            active-color="#2DEB79"
            inactive-color="#B9B9B9"
            :active-value="true"
            :inactive-value="false"
          />
        </el-form-item>
        <el-form-item v-if="!form.isAutoMode" prop="authorizationMode" class="item">
          <label>授权模式</label>
          <el-select v-model="form.authorizationMode" multiple>
            <el-option v-for="item in authorizationMode" :key="item.value" :value="item.value" :label="item.name"/>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose()">取消</el-button>
        <el-button type="primary" @click="checkFormSubmit()">确定</el-button>
    </span>
    </el-dialog>
    <secondAuthentication 
      :authenticationVisible.sync="authenticationVisible" 
      :phone="phone"
      :title="authenticationTitle"
      @handleSure="handleSure"
    />
  </div>
</template>

<script>
const pagination = () => import('@/components/pagination.vue')
const secondAuthentication = () => import('@/components/secondAuthentication.vue')
import { getUserInfo } from '@/api/usercenter'
// import { isComfirm } from '@/utils/utils'
import { 
  getClientList,
  delClient,
  getPowerList,
  addClient,
  editClient,
  getClientId,
  getClientSecret
  } from '@/api/clientManagement'
export default {
  name: "clientManagement",
  components:{
    pagination,
    secondAuthentication
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (!value.startsWith('http')) {
        callback(new Error('请输入正确的网址'));
      } else {
        callback();
      }
    };
    return {
      contract:{
        userinfo:'用户信息',
        system:'系统设置'
      },
      tableData:[],
      total:0,
      page:1,
      size:10,
      searchValue:'',
      visible:false,
      rowData:{},
      form:{
        name:'',
        isEnabled:true,
        clientId:'',
        clientSecret:'',
        contractScope:[],//签约权限
        eventCallbackUrl:'',
        isAutoMode:false,
        //授权模式
        authorizationMode:[],
        isCode:false,
        isImplicit:false,
        isPassword:false,
        isClient:false
      },
      powerList:[],
      authorizationMode:[
        {
          name:"授权码",
          value:'isCode'
        },{
          name:"密码式",
          value:'isPassword'
        },{
          name:"隐藏式",
          value:'isImplicit'
        },{
          name:"凭证式",
          value:'isClient'
        }
      ],
      rules:{
        name: [
          { required: true, message: '请输入姓名', trigger: ['blur','change'] },
        ],
        clientId:[
          { required: true, message: '请输入编号', trigger: ['blur','change'] },
        ],
        clientSecret:[
          { required: true, message: '请输入秘钥', trigger: ['blur','change'] },
        ],
        contractScope:[
          { required: true, message: '请输入签约权限', trigger: ['blur','change'] },
        ],
        eventCallbackUrl:[
          { required: true, message: '请输入网址', trigger: 'blur' },
          { validator: validatePass, trigger: ['blur', 'change'] }
        ],
        authorizationMode:[
          { required: true, message: '请输入事件回调地址', trigger: ['blur','change'] },
        ]
      },
      authenticationTitle:'',
      authenticationVisible:false,
      phone:'',
    };
  },
  watch:{
    size(){
      this.handleSearch(1)
    },
    visible(){
      this.$nextTick(()=>{
        this.$refs.form.clearValidate()
      })
    }
  },
  activated(){
    this.handleSearch()
    this.getPowers()
    this.getUserDetails()
  },
  methods:{
    handleClose(){
      this.visible = false;
    },
    //获取个人信息
    getUserDetails(){
      getUserInfo().then(res=>{
        if(res.code == 200){
          this.phone = res.data.phone
        }else{
          this.$message.error(res.msg || '接口出错')
        }
      })
    },
    //获取权限列表
    getPowers(){
      getPowerList().then(res=>{
        if(res.code==200){
          this.powerList = res.data
        }
      })
    },
    //查询
    handleSearch(page){
      page ? this.page = page : this.page = 1;
      let params = {
        current:this.page,
        size:this.size,
        name:this.searchValue
      }
      getClientList(params).then(res=>{
        if(res.code==200){
          this.tableData = res.data.records
          this.total = res.data.total
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    },
    //二次确认
    comfirm(row){
      // isComfirm(row,this.del)
      this.rowData = row
      this.authenticationTitle = '确认删除'
      this.authenticationVisible = true
    },
    //删除
    del(row){
      delClient(row.id).then(res=>{
        if(res.code==200){
          this.$message.success('删除成功')
          this.handleSearch()
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    },
    handleEdit(row){
      Object.assign(this.form,this.$options.data().form)
      if(row){
        // 编辑
        if(!row.isAutoMode){
          row.isCode && this.form.authorizationMode.push('isCode')
          row.isImplicit && this.form.authorizationMode.push('isImplicit')
          row.isPassword && this.form.authorizationMode.push('isPassword')
          row.isClient && this.form.authorizationMode.push('isClient')
        }
        this.form = {
          ...this.form,
          ...row,
          contractScope:row?.contractScope.split(',') || [],
        }
      }else{
        // 添加
        this.getId()
        this.getSecret()
      }
      this.rowData = row
      this.visible = true
    },
    // 获取编号
    getId(){
      getClientId().then(res=>{
        if(res.code == 200){
          this.form.clientId = res.data
        }
      })
    },
    // 获取秘钥
    getSecret(){
      getClientSecret().then(res=>{
        if(res.code == 200){
          this.form.clientSecret = res.data
        }
      })
    },
    // 提交校验
    checkFormSubmit(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          // this.onSave()
          if(this.rowData){
            this.authenticationTitle = '确认重置'
            this.authenticationVisible = true
          }else{
            this.onSave()
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 二次确认
    handleSure(type){
      if(type == '确认重置'){
        //编辑
        this.onSave()
      }else{
        //删除
        this.del(this.rowData)
      }
    },
    //新增或编辑
    onSave(){
      let Api = this.rowData ? editClient : addClient
      let {isAutoMode,authorizationMode,contractScope} = this.form
      let params = {
        ...this.form,
        contractScope:contractScope.join(','),
        isCode:isAutoMode ? false : authorizationMode.includes('isCode'),
        isImplicit:isAutoMode ? false : authorizationMode.includes('isImplicit'),
        isPassword:isAutoMode ? false : authorizationMode.includes('isPassword'),
        isClient: isAutoMode ? false : authorizationMode.includes('isClient'),
      }
      delete params.authorizationMode
      Api(params).then(res=>{
        this.visible = false
        if(res.code==200){
          this.$message.success('操作成功')
          this.handleSearch()
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/element-reset.scss';
.clientManagement{
  padding:10px 20px;
  position: relative;
  height: calc(100vh - 140px);
  text-align: left;
  color: #E8E8E8;
  .content{
    position: relative;
    background: #232323;
    min-height: calc(100vh - 250px);
    text-align: left;
    padding: 25px 35px 80px 35px;
    .title{
      font-size: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }
    img{
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
    .tool{
      width: 300px;
      display: flex;
      align-items: center;
    }
  }
  .greenStatus{
    &::before{
      content: '●';
      color: #2DEB79;
      margin-right: 5px;
      font-size: 20px;
    }
  }
 .redStatus{
    &::before{
      content: '●';
      color: #EB2D2D;
      margin-right: 5px;
      font-size: 20px;
    }
  }
}
::v-deep .clientDialog{
  .el-form{
    padding-bottom: 20px!important;
    .el-tag.el-tag--info{
      border-radius: 8px;
      background: #404040;
      color: #E8E8E8;
      font-size: 16px;
    }
    .el-dialog__title{
      font-size: 20px;
    }
    .el-form-item__label,.el-form-item__content{
      font-size: 16px;
    }
    .el-input{
      font-size: 16px;
    }
    label{
      font-size: 16px;
    }
  }
   .el-select{
    display: block;
  }
  .itemContent{
    display: flex;
    .el-button{
      margin-left: 10px;
    }
  }
}
</style>
