import {get,put,post,del} from '@/utils/http.js'

// 获取客户端列表
export function getClientList(params){
  return get('/oauth2/client/page',params)
}

// 获取权限列表
export function getPowerList(params){
  return get('/oauth2/client/scope/list',params)
}

// 获取新秘钥
export function getClientSecret(){
  return get('/oauth2/client/client-secret/new')
}

// 获取新编号
export function getClientId(){
  return get('/oauth2/client/client-id/new')
}

// 添加客户端信息
export function addClient(params){
  return post('/oauth2/client',params)
}

//编辑客户端信息
export function editClient(params){
  return put('/oauth2/client',params)
}

//删除客户端
export function delClient(id){
  return del(`/oauth2/client/${id}`)
}

//获取二次认证验证码
export function verificationCode(){
  return post('/captcha/reconfirm/captcha/send')
}

//二次认证
export function reconfirm(params){
  return post('/safe/reconfirm',params)
}